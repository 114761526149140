//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-735:_1892,_4492,_5512,_1004,_5324,_1380,_8148,_4224;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-735')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-735', "_1892,_4492,_5512,_1004,_5324,_1380,_8148,_4224");
        }
      }catch (ex) {
        console.error(ex);
      }