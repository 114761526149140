const baseFitmentFields = ['Year', 'Make', 'Model', 'Submodel'];
const extraFitmentFields = ['Drive'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const facetBarFields = [...wheelsFields, ...tiresFields];
const ignoreFields = [...facetBarFields, 'Universal', 'vehicle_position', 'FitmentType'];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/brands';
const wheelsCollectionUrl = '/collections/wheels-1';
const tiresCollectionUrl = '/collections/tires';
const isOnHomePage = window.location.pathname === '/';

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

const isAllProductsWheels = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Wheel');
};
const isAllProductsTires = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Tire');
};

function facetResponseHandler(response) {
  const categoryFacet = response.Facets.find(
    (facet) => facet.FieldName === 'category' && facet.Values.length,
  );
  if (categoryFacet) {
    const facetValues = categoryFacet.Values;

    // if there is no parent category
    let categoryName = !facetValues.some((v) => !v.Term.includes('>')) && facetValues[0].Term.split('>')[0];

    for (let i = 0; i < facetValues.length - 1; i++) {
      const value = facetValues[i];

      if (!value.Term.includes('>')) {
        categoryName = value.Term;
      } else if (
        value.Term.startsWith(`${categoryName}>Other-`) &&
        facetValues[i + 1].Term.includes(`${categoryName}>`)
      ) {
        const currentValue = facetValues[i];
        facetValues[i] = facetValues[i + 1];
        facetValues[i + 1] = currentValue;
      }
    }
  }

  return response;
}

function responseHandler(response) {
  return facetResponseHandler(response);
}

const getActiveCurrency = () => globalThis.Shopify.currency?.active;

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const activeCurrency = getActiveCurrency();
  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;

  const num = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: activeCurrency,
    currencyDisplay: 'narrowSymbol',
  }).format(price);
  return `${num} ${activeCurrency}`;
}

const updateCallback = () => {
  const brandTitle = window.document.querySelector('body.templateCollection .page-title .cm_brand-title');

  if (brandTitle) {
    const brands = window.Convermax.getSearchRequest().selection.filter((s) => s.field === 'vendor');

    if (!!brands && brands.length === 1 && brands[0].value) {
      brandTitle.innerHTML = ` ${brands[0].value}`;
    } else {
      brandTitle.innerHTML = '';
    }
  }
};

const getFitmentSearchTitle = () =>
  window.location.pathname === categorySelectionPageUrl
    ? 'Categories'
    : window.location.pathname === brandSelectionPageUrl
      ? 'Brands'
      : 'Parts';

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  responseHandler,
  facetResponseHandler,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: true,
    isVehicleSelectionIsolated: true,
    onVehicleSelected: () => {
      const menuCloseElement = window.document.querySelector(
        '.menu-mobile.opened ul.main-nav>li.back-prev-menu .icon-close',
      );
      if (menuCloseElement) {
        menuCloseElement.click();
      }
    },
    getFitmentSearchTitle,
  },
  product: {
    formatPrice,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset', 'wheel_backspace'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_main-content',
      template: 'MainContent',
    },
    {
      name: 'FacetPanel',
      location: '#cm_facet-panel',
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm_category-page',
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: { selector: '#cm_category-facet-panel', class: 'cm_FacetPanel__category-container' },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FrontRearFacetPanel',
      type: 'FacetPanel',
      fields: ['vehicle_position'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'collection_sort': 'Featured',
        'relevance': 'Best Match',
        'title': 'Name: A-Z',
        'title:desc': 'Name: Z-A',
        'price': 'Price: low to high',
        'price:desc': 'Price: high to low',
        'published_at': 'Oldest to newest',
        'published_at:desc': 'Newest to oldest',
      },
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'SearchBox',
      location: {
        replace: '.header-main.d-lg-flex .search-button',
        class: 'cm_search-box-root_container',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBox',
      location: {
        replace: '.header-main.d-lg-none:nth-of-type(2) .table-row .search-button',
        class: 'cm_search-box-root_container',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxHome',
      type: 'SearchBox',
      location: { selector: '#cm_home-searchbox', class: 'cm_search-box-root__home' },
      template: 'SearchBox',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.header-main.d-lg-flex .top-cart-holder',
        class: 'd-none d-lg-block',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        insertBefore: '.header-main.d-lg-none .m-cart-icon.cart-target',
        class: 'm-cart-icon cm_garage-mobile__container cm_garage-contaner',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HeaderVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm_home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 680,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm_home-wheels',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      isAutoRedirectDisabled: !isOnHomePage,
      redirectUrl: wheelsCollectionUrl,
      selectFields: wheelsFields,
      shouldBeIsolated: () => !isAllProductsWheels(),
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm_home-tires',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      isAutoRedirectDisabled: !isOnHomePage,
      redirectUrl: tiresCollectionUrl,
      selectFields: tiresFields,
      shouldBeIsolated: () => !isAllProductsTires(),
    },
    {
      name: 'VehicleLabel',
      type: 'VehicleWidget',
      location: {
        replace: 'body.templateCollection h1.page-title .cm-vehicle-title',
        class: 'cm_vehicle-widget_label__container',
      },
      template: 'fitmentSearch/vehicleLabelContainer',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      columnBreakpoint: 680,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: '#shopify-section-product-template .qty-add-cart' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: '#shopify-section-product-template a[href="#tabs-page-fitment"]',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: '#shopify-section-product-template #tabs-page-fitment',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: { selector: '#cm_category-selection-page', class: 'cm_vehicle-categories__categories' },
      template: 'facetTiles/hierarchicalContainer',
      isMultiLevel: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: { selector: '#cm_brand-selection-page', class: 'container cm_vehicle-categories__brands' },
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: { insertAfter: '.page-cata .meta-info', class: 'cm_subcategories' },
      template: 'facetTiles/subtitles',
      visibleIf: () => window.location.pathname.includes(`${categorySelectionPageUrl}/`),
      facetField: 'category',
    },
    {
      name: 'RelatedItems',
      location: '#cm_related-products',
      template: 'product/relatedItems',
      count: 12,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
